<template lang="pug">
  .dropdown-filters
    .inventory-groups
      .filter-title {{ $t("inventory_management.shared_inventory_group") }}
      AppDropdown.inventory-groups-dropdown(
        :followReferenceBoundary="false"
        name="inventory-groups-dropdown"
        close-on-select
        searchable
        :value="filters.inventoryGroup"
        :items="inventoryGroups"
        z-index="10003"
        :placeholder="$t('inventory_management.select_inventory_group')"
        @select="$emit('select:inventory-group', $event)"
        @remove="$emit('select:inventory-group', {})"
        order-direction="keep"
      )
    .car-classes
      .filter-title {{ $t("inventory_management.car_class") }}
      AppDropdown.car-class-name(
        v-if="isEmpty(filters.inventoryGroup)"
        :followReferenceBoundary="false"
        disabled
        z-index="10003"
        :placeholder="$t('inventory_management.select_car_classes')"
        v-b-tooltip.hover
        :title="$t('inventory_management.select_inventory_group_first')"
      )
      AppDropdown.car-class-name(
        v-else
        :followReferenceBoundary="false"
        name="car-class-name"
        checkbox
        batch-select
        multiple
        allow-empty
        searchable
        :is-item-disabled="isItemDisabled"
        :value="filters.carClasses"
        :items="carClasses"
        z-index="10003"
        order-direction="preorder"
        :placeholder="$t('inventory_management.select_car_classes')"
        @select="$emit('select:car-classes', $event)"
        :max-select-count="maxSelectCountCarClass"
        :tips="$t('general_settings.car_class_count_tip')"
      )
</template>

<script>
  import { isEmpty } from "lodash-es"
  import withMaxSelectCountCarClass from "@/mixins/withMaxSelectCountCarClass"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    mixins: [withMaxSelectCountCarClass],

    props: {
      inventoryGroups: Array,
      carClasses: {
        type: Array,
        default: () => new Array()
      },
      filters: Object,
      carClassesLoading: Boolean
    },

    methods: {
      isEmpty,

      isItemDisabled({ selectable }) {
        return !selectable
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/variables.sass"

  [name="inventory-groups-dropdown"],
  [name="car-class-name"]
    .app-select-items
      max-height: 200px !important
      @media screen and (max-height: 768px)
        max-height: calc(#{$content-height} - 350px)
</style>

<style lang="sass" scoped>
  .dropdown-filters
    display: flex

    .inventory-groups,
    .car-classes
      width: 50%

      .app-select
        margin-left: -8px
</style>
